// src/templates/pages/search.js

import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import Layout from '../components/Layout.js'
import ResourcesHeader from '../components/ResourcesHeader'

function Search(props) {
  const seoData = {
    seo: {
      title: 'Search - Arete',
    },
  }

  const locationSearch = new URLSearchParams(props.location.search)
  let searchQuery = locationSearch.get('s') || ''
  let pagedQuery = locationSearch.get('paged') || 1

  const [searchResults, setSearchResults] = useState(null)
  const [resultsPagination, setResultsPagination] = useState(null)

  useEffect(() => {
    if (searchQuery && searchQuery.length >= 3) {
      fetch(
        `${process.env.GATSBY_SEARCH_ROUTE}?s=${searchQuery}&paged=${pagedQuery}`
      )
        .then(response => response.json())
        .then(resultData => {
          if (resultData.data) {
            setSearchResults(buildSearchResults(resultData.data))
            setResultsPagination(buildPagination(resultData.pagination))
          } else {
            setSearchResults(<li>There are no results for this query</li>)
          }
        })
    }
  }, [searchQuery, pagedQuery])

  const handleChange = function (event) {
    searchQuery = event.target.value
  }

  const buildSearchResults = function (data) {
    if (data !== null) {
      return data.map((node, index) => {
        return (
          <li
            key={index}
            className="relative space-y-responsive-xl-12 py-responsive-xl-32"
          >
            <p className="block text-nav uppercase">{node.post_type_label}</p>
            <Link
              to={node.url}
              className="block no-underline after:absolute after:inset-0 after:block after:content-['']"
            >
              <h3 className="headline-sm">{node.title}</h3>
            </Link>
            <p>{node.description}</p>
          </li>
        )
      })
    } else {
      return <li>There are no results for this query</li>
    }
  }

  const buildPagination = function (data) {
    if (data.max_num_pages > 1) {
      let paginationArr = []

      if (data.current_page > 1) {
        paginationArr.push({
          url: `/search/?s=${searchQuery}&paged=${data.current_page - 1}`,
          text: '← Previous',
          current: false,
        })
      }

      for (let i = 1, len = data.max_num_pages; i <= len; i++) {
        paginationArr.push({
          url: `/search/?s=${searchQuery}&paged=${i}`,
          text: `${i}`,
          current: i === data.current_page ? true : false,
        })
      }

      if (data.current_page !== data.max_num_pages) {
        paginationArr.push({
          url: `/search/?s=${searchQuery}&paged=${data.current_page + 1}`,
          text: 'Next →',
          current: false,
        })
      }

      return paginationArr.map((node, index) => {
        return (
          <li
            key={index}
            className="flex items-center justify-center border-b-2 border-transparent p-responsive-xl-8 no-underline"
          >
            <Link
              to={node.url}
              className={
                node.current
                  ? 'border-b-2 border-fuscia-full no-underline'
                  : 'border-b-2 border-transparent no-underline'
              }
            >
              {node.text}
            </Link>
          </li>
        )
      })
    }
  }

  return (
    <Layout>
      <Seo post={seoData} />

      <ResourcesHeader
        title="Search Results"
        description={'Search results for: <i>' + searchQuery + '</i>'}
      />

      <section className="search">
        <div className="container-inner">
          <form
            action="/search/"
            method="get"
            autoComplete="off"
            className="flex"
          >
            <label htmlFor="search">
              <span className="sr-only">Search this site</span>
            </label>
            <input
              defaultValue={searchQuery}
              onChange={handleChange}
              type="text"
              id="search"
              placeholder="Search"
              className="grow rounded-md border border-granite-200 p-responsive-xl-8"
              name="s"
            />
            <button
              type="submit"
              className="btn btn-primary ml-responsive-xl-32"
            >
              Search
            </button>
          </form>
        </div>
      </section>

      <section className="results">
        <div className="container">
          <ul className="divide-y divide-granite-200">{searchResults}</ul>
        </div>
      </section>

      <ul className="flex flex-row flex-wrap items-center justify-center space-x-responsive-xl-8">
        {resultsPagination}
      </ul>
    </Layout>
  )
}

export default Search
